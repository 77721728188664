<script setup lang="ts">
const viewport = useViewport()

const props = defineProps({
  title: {
    type: String,
    default: undefined,
    required: false
  },
  logo: {
    type: Object as PropType<{
      url?: string
      width?: number | null
      height?: number | null
    }>,
    default: undefined,
    required: false
  }
})

const dimension = computed(() => {
  const maxHeight = viewport.isLessThan('lg') ? 32 : 32
  const maxWidth = viewport.isLessThan('lg') ? 32 : 32
  const height = Math.min(maxHeight, props.logo?.height || maxHeight)

  // if no sizes, return a square
  if (!props.logo?.width || !props.logo?.height)
    return {
      width: height,
      height
    }
  // compute the ratio
  const ratio = props.logo?.width / props.logo?.height
  const width = Math.round(height * ratio)

  if (width > maxWidth) {
    return {
      width: maxWidth,
      height: Math.round(maxWidth / ratio)
    }
  }

  return {
    width,
    height
  }
})
</script>

<template>
  <nuxt-picture
    format="webp"
    background="transparent"
    :src="props.logo?.url"
    :alt="`${title}=campaign-logo`"
    :width="dimension.width"
    :height="dimension.height"
    sizes="sm: 100vw md:200px"
    position="left top"
    loading="lazy"
    fetchpriority="low"
    decoding="async"
  />
</template>
